<template>
  <div class="survey-select">
    <select
        :class="[classes]"
        v-model="localValue"
      >
      <option v-if="label" value="null" disabled selected>{{ label }}</option>
      <template  v-for="(item, index) in items">
        <slot v-bind="{item, index}">
          <option :key="index" :value="item[valueKey]" v-text="item[nameKey]"></option>
        </slot>
      </template>
    </select>
  </div>
</template>

<script>
export default {
    name: 'InputSelect',
    props: {
      items: {
        type: [Array, Object],
        required: true
      },
      nameKey: {
        type: String,
        default: 'title'
      },
      valueKey: {
        type: String,
        default: 'id'
      },
      value: {
        required: false
      },
      label: {
        type: String,
        required: false
      },
      outerLabel: Boolean,
      classes: {
        type: String,
        default: 'fw-500 fs-10 text-secondary-one pr-select-dropdown param-dropdown bg-transparent w-full i-border-1 border-secondary-five rounded-sm py-1'
      },
      labelClasses: {
        type: String,
        default: 'text-secondary-two fs-10 fw-500'
      }
    },
    computed: {
      localValue: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
          this.$emit('change')
        }
      }
    }
}
</script>

<style scoped>
  
  .survey-select select {
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
    position: relative;
  }
</style>
