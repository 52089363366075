<template>
  <div class="i-root-layer relative flex">
    <div
      class="shadow-one assess-middle-layer bg-primary-three px-10 pb-5 rounded assess-root-box"
      :class="{'assess-acceptance-box': !showFeedback}"
      >
      <Result
        v-if="checkUser === 'author'"
        :assessItem="assessItem"
        :showFeedback="showFeedback"
        @moveToFeedback="moveToFeedback"
        :ref="'assessment_result'"
      >
        <div v-if="chartData.labels">
          <IntelBarChart
            :break-the-title="true"
            :styles="{width: '622px', height: '181px'}"
            :chartData="chartData"
          />
        </div>
      </Result>
      <Feedback
        v-else-if="checkUser === 'assignee'"
        :assessItem="assessItem"
        :showFeedback="showFeedback"
        @moveToFeedback="moveToFeedback"
        :ref="'assessment_result'"
      >
        <div v-if="chartData.labels">
          <IntelBarChart
            :break-the-title="true"
            :styles="{width: '622px', height: '181px'}"
            :chartData="chartData"
          />
        </div>
      </Feedback>
    </div>
    <FeedbackRight :assessItem="assessItem" v-if="showFeedback" />
  </div>
</template>

<script>
import Feedback from "./Feedback";
import Result from "./Result";
import FeedbackRight from "./FeedbackRight";
import IntelBarChart from  '@/components/roadmap/canvas/widgets/BarChart.js'
import {mapGetters} from "vuex";

export default {
  name: "RightPart",
  components: {FeedbackRight, Feedback, Result, IntelBarChart},
  props: ['assessItem', 'showFeedback', 'chartData'],
  created() {
    this.searchObj = {
      created_by: null,
      location: null,
      function: null,
      created_at: null
    }
  },
  data() {
    return {
      serverMode: process.env.NODE_ENV,
      searchObj: {
        created_by: null,
        location: null,
        function: null,
        created_at: null
      },
      ids: []
    }
  },
  methods: {
    sendScreen() {
      this.$store.dispatch("assessment/setWorkingAssessment", this.$route.params.assessment_id);
      this.$router.push({name: 'intel.assess', params: {id: this.$route.params.id}});
    },
    checkAccessability(user) {
      if (!user) {
        this.$router.push({name: 'workspace_dashboard'});
      }
    },
    getUserName(user) {
      let name = '';
      if (user && user.assignee && user.assignee.first_name) {
        name = user.assignee.first_name;
        if (user.assignee.last_name) {
          name = name + ' ' + user.assignee.last_name;
        }
        return name;
      }
      name = user.email;
      return name;
    },
    loadAssessmentValuesForResponder() {
      this.$store.dispatch("assessment/getAssessmentValueForResponder", {
        assessment_id: this.$route.params.assessment_id
      });
    },
    loadAssessmentValues() {
      const self = this;
      self.$store.dispatch("assessment/getAssessmentValueForCreator", {
        assessment_id: self.$route.params.assessment_id,
        childs: self.getChildIds()
      });
    },
    moveToFeedback() {
      this.$emit("moveToFeedback");
    },
    prepareResponderAverage() {
      this.$refs.assessment_result.renderAverage();
    }
  },
  computed: {
    ...mapGetters({
      assessmentInfo: "assessment/assessmentInfo",
      participation: "assessment/participationStatus"
    }),
    getAuthUser() {
      let user = JSON.parse(localStorage.getItem('_user'));
      if (user) {
        return user;
      }
      return null;
    },
    //   checkParticipationStatus() {
    //     let assignee = this.assessmentInfo.assignee.find(el => el.assignee_id == this.getAuthUser.id)
    //     return assignee.participation_status
    //   },
    checkUser() {
      let user = null
      if (this.assessmentInfo && this.getAuthUser) {
        if (this.assessmentInfo.created_by == this.getAuthUser.id) {
          user = 'author';
        } else if (this.assessmentInfo.assignee.find(el => el.assignee_id == this.getAuthUser.id)) {
          user = 'assignee';
        }

        this.checkAccessability(user);
      }
      return user
    },
    getAssignee() {
      if(this.assessmentInfo && this.assessmentInfo.assignee && this.getAuthUser) {
        return this.assessmentInfo.assignee.find(item => item.assignee_id === this.getAuthUser.id && item.participation_status);
      }
      return false;
    }
  },
  watch: {
    assessItem: function (val) {
      this.$refs.assessment_result.scrollToIdentifier(val.id);
    }
  }
}
</script>

<style scoped>
</style>
