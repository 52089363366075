<template>
  <div>
    <div class="mt-8 assessment-preview-div overflow-y-auto ws-scrollbar pr-8" id="scrollDiv" v-if="showFeedback">
      <div class="flex flex-col" id="assess_content">
        <!--Level 1-->
        <div class="flex flex-col i-border-b-2 " v-for="(l1, l1Index) in getScenarioCollection()"
             :key="l1Index" :class="{'mt-4': l1Index > 0}" :id="'identifier_group_' + l1Index">
          <div>
            <h3 class="font-poppins fw-600 text-primary-one fs-18">{{ getL1Indexing(l1Index, 1) }} {{ l1.title }}</h3>
            <p class="font-poppins text-primary-one fs-14" >{{ l1.description ? l1.description : 'No description available' }}</p>

            <div v-if="l1.child.length > 0" class="i-pb-10 mt-4">
              <ResultComponentL1>
                <template v-slot="{selectedPropertyId}">
                  <IntelBarChart
                    :break-the-title="true"
                    :styles="{width: '690px', height: '128px'}"
                    :chartData="getGroupChartData(assessmentModule, l1, selectedPropertyId)"
                  />
                </template>
              </ResultComponentL1>
            </div>
            <div class="i-pb-10 mt-4" v-else>
              <Survey
                  :parent="l1"
                  :items="assessmentModule.assess_properties"
                  :updateProgress="updateProgress"
                  :updateReview="updateReview"
              />
            </div>
          </div>
          <hr>
          <!--Level 2-->
          <div class="flex flex-col mt-4" v-for="(l2, l2Index) in l1.child" :key="l2Index"
               :class="{'i-border-b-1 border-solid border-secondary-five': l2.child.length <= 0, 'mt-0': l1.child && l1.child.length > 0 && l2Index === 0 && l2.child.length > 0}">
            <div v-if="l2.child && l2.child.length <= 0">
              <h3 class="font-poppins fw-600 text-primary-one fs-18">{{ getSubLevelIndexing(l2Index, getL1Indexing(l1Index, 1), 2) }} {{ l2.title }}</h3>
              <p class="font-poppins text-primary-one fs-14" :class="{'i-border-b-1 border-solid border-secondary-five i-pb-30': l2.child.length > 0}">{{ l2.description ? l2.description : 'No description available' }}</p>

              <div class="i-pb-10 mt-4" :class="{'d-none': l2.child.length > 0}">
                <Survey
                    :parent="l2"
                    :items="assessmentModule.assess_properties"
                    :updateProgress="updateProgress"
                    :updateReview="updateReview"
                />
              </div>
            </div>

            <!--Level 3-->
            <div class="flex flex-col mt-4" v-for="(l3, l3Index) in l2.child" :key="l3Index"
                 :class="{'i-border-b-1 border-solid border-secondary-five': l3.child.length <= 0, 'mt-0': l2.child && l2.child.length > 0 && l3Index === 0}">
              <div v-if="l3.child && l3.child.length <= 0">
                <h3 class="font-poppins fw-600 text-primary-one fs-18">{{ getSubLevelIndexing(l3Index, getSubLevelIndexing(l2Index, getL1Indexing(l1Index, 1), 2), 3) }} {{ l3.title }}</h3>
                <p class="font-poppins text-primary-one fs-14" :class="{'i-border-b-1 border-solid border-secondary-five i-pb-30': l3.child.length > 0}">{{ l3.description ? l3.description : 'No description available' }}</p>

                <div class="i-pb-10 mt-4" :class="{'d-none': l3.child.length > 0}">
                  <Survey
                      :parent="l3"
                      :items="assessmentModule.assess_properties"
                      :updateProgress="updateProgress"
                      :updateReview="updateReview"
                  />
                </div>
              </div>

              <!--Level 4-->
              <div class="flex flex-col mt-4 i-border-b-1 border-solid border-secondary-five" v-for="(l4, l4Index) in l3.child" :key="l4Index">
                <div>
                  <h3 class="font-poppins fw-600 text-primary-one fs-18">{{ getSubLevelIndexing(l4Index, getSubLevelIndexing(l3Index, getSubLevelIndexing(l2Index, getL1Indexing(l1Index, 1), 2), 3), 4) }} {{ l4.title }}</h3>
                  <p class="font-poppins text-primary-one fs-14">{{ l4.description ? l4.description : 'No description available' }}</p>

                  <div class="i-pb-10 mt-4" :class="{'d-none': l4.child.length > 0}">
                    <Survey
                      :parent="l4"
                      :items="assessmentModule.assess_properties"
                      :updateProgress="updateProgress"
                      :updateReview="updateReview"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full assess-acceptance overflow-y-auto" v-else>
      <h2 class="font-poppins fw-500 text-primary-one fs-36">
          {{ assessmentInfo ? assessmentInfo.title : '' }}
      </h2>
      <p class="fs-18 fw-500 font-poppins text-primary-one i-pb-36 i-border-b-1 border-solid border-secondary-four">
        Powered by Instrat360.com
      </p>
      <div class="flex i-mt-50 assess-img-pb i-border-b-1 border-solid border-secondary-four">
        <div class="w-full">
          <p
            class="fs-20 font-poppins text-secondary-one"
          >
            {{ assessmentInfo.message }}
          </p>
        </div>
        <div>
          <slot />
        </div>
      </div>
      <div class="flex justify-between items-center mt-10">
        <div
          class="font-poppins fw-600 fs-20 text-primary-three bg-primary-one i-pt-15 i-pb-15 assess-accept-btn rounded italic cursor-pointer"
          @click="getStartedOrContribute()"
        >
          Get started or continue to contribute
        </div>
      </div>
      <div class="flex justify-end i-mt-50">
        <img src="/images/assess_logo.svg" alt="image" class="assess-logo">
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import IntelBarChart from  '@/components/roadmap/canvas/widgets/BarChart.js'
import Survey from '@/components/assessment/survey/Survey.vue'
import ResultComponentL1 from '@/components/assessment/survey/ResultComponentL1.vue'
import $ from "jquery";

export default {
  name: "Feedback",
  components: {
    Survey,
    IntelBarChart,
    ResultComponentL1
  },
  props: ['assessItem', 'showFeedback'],
  data() {
    return {
      reviewCollector: [],
      historyCollector: [],
      showChart: true
    }
  },

  computed: {
    ...mapGetters({
      assessmentInfo: "assessment/assessmentInfo",
      assessmentModule: "assessment/assessmentModule",
      assessmentValues: "assessment/assessmentResponderValues",
      participation: "assessment/participationStatus"
    }),
    getAuthUser() {
      let user = JSON.parse(localStorage.getItem('_user'));
      if (user) {
        return user;
      }
      return null;
    },
    getAssignee() {
      if(this.assessmentInfo && this.assessmentInfo.assignee && this.getAuthUser) {
        return this.assessmentInfo.assignee.find(item => item.assignee_id === this.getAuthUser.id && item.participation_status);
      }
      return false;
    },
    getDefinitionRanges() {
      if(this.assessmentInfo.definition_id && this.assessmentInfo.definition && this.assessmentInfo.definition.items) {
        let definition = this.assessmentInfo.definition.items.find(definition => definition.type === 1);
        if(definition) {
          return definition.range_with_definition;
        }
      }
      return [];
    },
  },

  watch: {
    assessmentValues(newVal) {
      this.renderAverage()
    }
  },

  methods: {
    toggleChart() {
      this.showChart = !this.showChart
    },
    getStarted() {
      this.$Progress.start();
      let data = {}
      data.id = this.assessmentInfo.assignee.find(el => el.assignee_id == this.getAuthUser.id).id
      data.assessment_id = this.$route.params.assessment_id
      data.participation_status = true
      this.$store.dispatch('assessment/updateAssignee', data)
          .then(response => {
            this.$Progress.finish();
          });
      this.$store.dispatch("assessment/getScenarioCollection", {
        assessment_id: this.$route.params.assessment_id
      }).then(response => {
        //
      });
    },
    getStartedOrContribute() {
      this.$emit("moveToFeedback");
    },
    sendScreen() {
      this.$store.dispatch("assessment/setWorkingAssessment", this.$route.params.assessment_id);
      this.$router.push({name: 'intel.assess', params: {id: this.$route.params.id}});
    },
    getPropertyReviewValue(scenario_collection_id, property_collection_id) {
      let valueData = this.assessmentValues.find(
          el => el.scenario_collection_id == scenario_collection_id
              && el.property_collection_id == property_collection_id
      )
      let value = valueData ? valueData.sample_value : 0
      return value
    },
    updateProgress(e, scenario_collection_id, property_collection_id) {
      let id = document.getElementById('progress-track-' + scenario_collection_id + '-' + property_collection_id)
      let value = parseFloat(e.target.value)
      let trackWidth = value * 10 + '%'
      id.style.width = trackWidth
    },
    updateReview(e, scenario_collection_id, property_collection_id) {
      let value = parseFloat(e.target.value)
      if(value > 10) {
        value = 10
      }
      let data = {}
      let assessment_assignee_id = this.assessmentInfo.assignee.find(el => el.assignee_id == this.getAuthUser.id).id
      data.assessment_id = this.$route.params.assessment_id
      data.assessment_assignee_id = assessment_assignee_id
      data.scenario_collection_id = scenario_collection_id
      data.property_collection_id = property_collection_id
      data.sample_value = value
      this.$Progress.start();
      // this.toggleChart()
      this.$store.dispatch("assessment/storeAssessmentValue", data)
          .then(response => {
            this.$Progress.finish();
            // setTimeout(() => this.renderAverage(), 5000)
            // this.renderAverage()
          });

      // let check = this.reviewCollector.find(el =>
      //     el.scenario_collection_id == scenario_collection_id &&
      //     el.property_collection_id == property_collection_id
      // )
      // if(check) {
      //    check.sample_value =  value
      // }
      // else {
      //    this.reviewCollector.push(data)
      // }

      // collect history
      // let slides = document.getElementsByClassName('progress-track')
      // slides.forEach(el => {
      //     let historyData = {}
      //     historyData.assessment_id = this.$route.params.assessment_id
      //     historyData.assessment_assignee_id = assessment_assignee_id
      //     historyData.scenario_collection_id = parseInt(el.id.split('-')[2])
      //     historyData.property_collection_id = parseInt(el.id.split('-')[3])
      //     historyData.sample_value = el.style.width.split('%')[0]/10

      //     let historycheck = this.historyCollector.find(el =>
      //         el.scenario_collection_id == historyData.scenario_collection_id &&
      //         el.property_collection_id == historyData.property_collection_id
      //     )
      //     if(historycheck) {
      //       historycheck.sample_value =  historyData.sample_value
      //     }
      //     else {
      //       this.historyCollector.push(historyData)
      //     }
      // });
      // this.renderAverage();
    },
    saveReview(e, scenario_collection_id, property_collection_id) {
      // save review
      this.$Progress.start();
      this.$store.dispatch("assessment/storeAssessmentValue", {
        assessment_id: this.$route.params.assessment_id,
        data: this.reviewCollector
      }).then(response => {
        this.reviewCollector = []
        this.$Progress.finish();
      });
      // save history
      this.$store.dispatch("assessment/storeAssessmentHistory", {
        assessment_id: this.$route.params.assessment_id,
        data: this.historyCollector
      }).then(response => {
        this.historyCollector = []
      });
    },
    getIndexing(index) {
      let indexSerial = 1;
      if (this.assessItem.is_module) {
        indexSerial = this.assessItem.indexing_info.slice(0, -1) + '' + (index + 1);
      } else {
        indexSerial = this.assessItem.indexing_info + '.' + (index + 1);
      }
      return indexSerial;
    },
    getL3ChildIndex(pageParentIndex, itemIndex) {
      let parentIndex = this.getIndexing(pageParentIndex);
      return parentIndex + '.' + (itemIndex + 1);
    },
    getL4ChildIndex(pageParentIndex, parentIndex, itemIndex) {
      let itemParentIndex = this.getIndexing(pageParentIndex);
      itemParentIndex = itemParentIndex + '.' + (parentIndex + 1);
      return itemParentIndex + '.' + (itemIndex + 1)
    },
    getScenarioCollection() {
      if(this.assessmentModule && this.assessmentModule.scenario_collections) {
        return this.assessmentModule.scenario_collections;
      }
      return [];
    },
    getL1Indexing(index, selfLevel) {
      let settings = this.assessmentModule.collection_settings;
      if(settings) {
        let collection = settings.collections[selfLevel];
        return parseFloat(collection.index_number) + index;
      }
      return '';
    },
    getSubLevelIndexing(index, currentData, selfLevel) {
      return currentData + '.' + (index + 1);
    },
    scrollToIdentifier(id) {
      let index = this.getScenarioCollection().findIndex(item => parseInt(item.id) === parseInt(id));
      if(index > 0) {
        let scrolledPos = 0;
        this.getScenarioCollection().forEach((item, iIndex) => {
          if(iIndex < index) {
            scrolledPos += $("#identifier_group_" + iIndex).height();
          }
        });

        $("#scrollDiv").animate({
          scrollTop: scrolledPos
          // scrollTop: scrolledPos + $("#assess_content").height() + (30 + (index + 1)) + (25 * (index + 1))
        }, 800);
      } else {
        $("#scrollDiv").animate({
          scrollTop: 0
        }, 800);
      }
    },
    getPropertyOriginalValue(item, pCollectionId) {
      let value = 0;
      let pCollectionItem = item.properties?.find(pItem => parseInt(pItem.property_collection_id) === parseInt(pCollectionId));
      if (pCollectionItem) {
        value = parseFloat(pCollectionItem.sample_value);
      }
      return value.toFixed(2);
    },
    getGroupChartData(module, item, selectedId) {
      let datasets = [
        {
          label: 'Assessment Properties',
          backgroundColor: [],
          data: []
        }
      ];
      module.assess_properties.forEach(prop => {
        let assess_property = item.assess_properties_responder?.find(data => data.property_collection_id === prop.id);
        let assess_value = assess_property && assess_property.sample_value ? assess_property.sample_value : 0;
        datasets[0].data.push(assess_value.toFixed(2));
        let bgColor = this.getBg(assess_value.toFixed(2))
        if(selectedId && selectedId != prop.id) {
          bgColor = this.addOpacityToBg(bgColor, .5)
        }
        datasets[0].backgroundColor.push(bgColor);
      });

      return {
        labels: module.assess_properties.map(data => data.title),
        datasets: datasets,
      };
    },
    getBg(value) {
      let definition = this.assessmentInfo?.definition_style
      if(value > 0) {
        value = value - .000001;
      }
      value = Math.floor(value / 2);
      if(value > 4) {
        value = 4;
      }
      if(definition.length > value) {
        return definition[value].bg;
      }
      return '#0075FF'
    },
    addOpacityToBg(color, opacity) {
      let _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
      return color + _opacity.toString(16).toUpperCase();
    },
    getAverage(data, assessmentValues, properties) {
      properties.forEach(prop => {
        //this.assessmentModule.averages = this.assessmentModule && this.assessmentModule.averages && this.assessmentModule.averages.length !== properties.length ? this.assessmentModule.averages : [];
          // let sum = 0;
        data.forEach(item => {
          let avg = this.getChildAverage(item, assessmentValues, prop, properties);
          this.pushToValueArray(item.assess_properties_responder, prop, avg);
        });

        // let avg = sum > 0 ? sum / data.length : 0;

      });
    },
    getChildAverage(data, assessmentValues, prop, properties) {
      if(data.child && data.child.length > 0) {
        let sum = 0;
        data.assess_properties_responder = data.assess_properties_responder && data.assess_properties_responder.length !== properties.length ? data.assess_properties_responder : [];
        data.child.forEach(item => {
          sum += this.getChildAverage(item, assessmentValues, prop, properties);
        });

        let avg = sum > 0 ? sum / data.child.length : 0;
        this.pushToValueArray(data.assess_properties_responder, prop, avg, data);

        return avg;
      } else {
        let avg = this.getPropertyAvgValue(data, prop.id, assessmentValues);
        data.assess_properties_responder = data.assess_properties_responder ? data.assess_properties_responder : [];
        this.pushToValueArray(data.assess_properties_responder, prop, avg, data);
        return avg;
      }
    },

    getPropertyAvgValue(item, pCollectionId, assessmentValues) {
      let summation = 0;
      let assessProps = assessmentValues.filter(assessValue => parseInt(item.id) === parseInt(assessValue.scenario_collection_id) &&
          parseInt(pCollectionId) === parseInt(assessValue.property_collection_id));

      assessProps.filter(assessValue => {
        if (parseInt(item.id) === parseInt(assessValue.scenario_collection_id) &&
            parseInt(pCollectionId) === parseInt(assessValue.property_collection_id)) {
          summation += assessValue.sample_value;
        }
      });

      return assessProps.length > 0 ? parseFloat(summation / assessProps.length) : summation;
    },

    pushToValueArray(data, property, value, item = null) {
      let propData = [];
      if(item) {
        propData = item.assess_properties_responder.find(prop => prop.property_collection_id === property.id);
      }
      if(propData) {
        propData.sample_value = value ? value : 0;
      } else {
        data.push({
          property_collection_id: property.id,
          scenario_collection_id: item && item.id ? item.id : null,
          sample_value: value ? value : 0
        });
      }
    },
    renderAverage() {
      this.toggleChart()
      let properties = this.assessmentModule && this.assessmentModule.assess_properties ? this.assessmentModule.assess_properties : [];
      if(properties) {
        this.getAverage(this.getScenarioCollection(), this.assessmentValues, properties);
      }
      this.toggleChart()
    },


  },
}
</script>

<style scoped>
.value-track {
  background: #414141;
  width: 100%;
  height: 2px;
}
.progress-track {
  height: 2px;
}
.range {
  height: 2px;
  width: 100%;
  outline: none;
  -webkit-appearance: none;
}
input[type='range']::-webkit-slider-thumb {
  width: 30px;
  -webkit-appearance: none;
  height: 15px !important;
  cursor: pointer;
  background: #00A7FE;
  border-radius: 2px;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
}
.start-title {
  line-height: 35px;
}
.start-subject {
  font-size: 24px;
  line-height: 28px;
}

.start-desc {
  height: 380px;
  line-height: 150%;
}

.start-button {
  height: 40px;
  background: #2A2A2E;
  border-radius: 2px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.35);
  width: 150px;
  cursor: pointer;
}
.closing-border {
  /*padding-bottom: 30px;*/
  border-bottom: 1px solid #414141;
}
.ml--1 {
  margin-left: -1px;
}
.box-mx-30 {
  margin-left: 30px;
  margin-right: 30px;
}
</style>
