<template>
  <div>
    <div class="assessment-preview-div overflow-y-auto ws-scrollbar pr-8 mt-4" id="scrollDiv" v-if="showFeedback">
      <div class="flex flex-col" id="assess_content">
        <!--Level 1-->
        <div class="flex flex-col i-border-b-2 border-primary-one border-solid" v-for="(l1, l1Index) in getScenarioCollection()"
             :key="l1Index" :class="{'i-mt-30': l1Index > 0}" :id="'identifier_group_' + l1Index">
          <div>
            <h3 class="font-poppins fw-600 text-primary-one fs-18">{{ getL1Indexing(l1Index, 1) }} {{ l1.title }}</h3>
            <p class="font-poppins text-primary-one fs-14" >{{ l1.description ? l1.description : 'No description available' }}</p>

            <div class="i-pb-10 i-mt-25">
              <ResultComponentL1>
                  <template v-slot="{selectedPropertyId}">
                    <IntelBarChart
                      :break-the-title="true"
                      :styles="{width: '495px', height: '128px'}"
                      :chartData="getGroupChartData(assessmentModule, l1, selectedPropertyId)"
                    />
                  </template>
              </ResultComponentL1>
            </div>
          </div>
          <hr>
          <!--Level 2-->
          <div
            class="flex flex-col i-mt-30"
            v-for="(l2, l2Index) in l1.child"
            :key="l2Index"
            :class="{'i-border-b-1 border-solid border-secondary-five': l2.child.length <= 0, 'mt-0': l1.child && l1.child.length > 0 && l2Index === 0}"
          >
            <div v-if="l2.child && l2.child.length <= 0">
              <h3 class="font-poppins fw-600 text-primary-one fs-18">{{ getSubLevelIndexing(l2Index, getL1Indexing(l1Index, 1), 2) }} {{ l2.title }}</h3>
              <p class="font-poppins text-primary-one fs-14" :class="{'i-border-b-1 border-solid border-secondary-five i-pb-30': l2.child.length > 0}">{{ l2.description ? l2.description : 'No description available' }}</p>

              <div class="i-pb-10 i-mt-25" :class="{'d-none': l2.child.length > 0}">
              <ResultComponent
                v-slot="{selectedPropertyId}"
                :getPropertyAvgValue="getPropertyAvgValue"
                :properties="assessmentModule.assess_properties"
                :parent="l2"
              >
                <IntelBarChart
                  :break-the-title="true"
                  :styles="barStyles"
                  :chartData="getGroupChartData(assessmentModule, l2, selectedPropertyId)"
                />
              </ResultComponent>
              </div>
            </div>

            <!--Level 3-->
            <div class="flex flex-col i-mt-30" v-for="(l3, l3Index) in l2.child" :key="l3Index"
                 :class="{'i-border-b-1 border-solid border-secondary-five': l3.child.length <= 0, 'mt-0': l2.child && l2.child.length > 0 && l3Index === 0}">
              <div v-if="l3.child && l3.child.length <= 0">
                <h3 class="font-poppins fw-600 text-primary-one fs-18">{{ getSubLevelIndexing(l3Index, getSubLevelIndexing(l2Index, getL1Indexing(l1Index, 1), 2), 3) }} {{ l3.title }}</h3>
                <p class="font-poppins text-primary-one fs-14" :class="{'i-border-b-1 border-solid border-secondary-five i-pb-30': l3.child.length > 0}">{{ l3.description ? l3.description : 'No description available' }}</p>

                <div class="i-pb-10 i-mt-25" :class="{'d-none': l3.child.length > 0}">
                  <ResultComponent
                    v-slot="{selectedPropertyId}"
                    :getPropertyAvgValue="getPropertyAvgValue"
                    :properties="assessmentModule.assess_properties"
                    :parent="l3"
                  >
                    <IntelBarChart
                      :break-the-title="true"
                      :styles="barStyles"
                      :chartData="getGroupChartData(assessmentModule, l3, selectedPropertyId)"
                  />
                  </ResultComponent>
                </div>
              </div>

              <!--Level 4-->
              <div class="flex flex-col i-mt-30 i-border-b-1 border-solid border-secondary-five" v-for="(l4, l4Index) in l3.child" :key="l4Index">
                <div>
                  <h3 class="font-poppins fw-600 text-primary-one fs-18">{{ getSubLevelIndexing(l4Index, getSubLevelIndexing(l3Index, getSubLevelIndexing(l2Index, getL1Indexing(l1Index, 1), 2), 3), 4) }} {{ l4.title }}</h3>
                  <p class="font-poppins text-primary-one fs-14">{{ l4.description ? l4.description : 'No description available' }}</p>

                  <div class="i-pb-10 i-mt-25" :class="{'d-none': l4.child.length > 0}">
                    <ResultComponent
                      v-slot="{selectedPropertyId}"
                      :getPropertyAvgValue="getPropertyAvgValue"
                      :properties="assessmentModule.assess_properties"
                      :parent="l4"
                    >
                      <IntelBarChart
                        :break-the-title="true"
                        :styles="barStyles"
                        :chartData="getGroupChartData(assessmentModule, l4, selectedPropertyId)"
                      />
                    </ResultComponent>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full assess-acceptance overflow-y-auto" v-else>
      <h2 class="font-poppins fw-600 text-primary-one fs-36">{{ assessmentInfo ? assessmentInfo.title : '' }}</h2>
      <p class="fs-18 fw-600 font-poppins text-primary-one i-pb-36 i-border-b-1 border-solid border-secondary-five">Powered by Instrat360.com</p>
      <div class="flex i-mt-50 assess-img-pb i-border-b-1 border-solid border-secondary-four">
        <div class="w-full">
          <p
            class="fs-20 font-poppins text-secondary-one"
          >
            {{ assessmentInfo.message }}
          </p>
        </div>
        <div>
          <slot />
        </div>
      </div>
      <div class="flex justify-between items-center mt-10">
        <div class="font-poppins fw-600 fs-20 text-primary-three bg-primary-one i-pt-15 i-pb-15 assess-accept-btn rounded italic cursor-pointer"
             @click="getStartedOrContribute()">
          See contributions
        </div>
        <!-- <div class="assess-bar-chart bg-transparent">
          <img src="/images/assessment_bar_chart.svg" alt="image" class="assess-bar-chart">
        </div> -->
      </div>
      <div class="flex justify-end i-mt-50">
        <img src="/images/assess_logo.svg" alt="image" class="assess-logo">
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ResultComponent from '@/components/assessment/survey/ResultComponent.vue'
import InputSelect from '@/components/assessment/survey/InputSelect.vue'
import IntelBarChart from  '@/components/roadmap/canvas/widgets/BarChart.js'
import ResultComponentL1 from '@/components/assessment/survey/ResultComponentL1.vue'
import $ from "jquery";

export default {
  name: "Result",
  components: {
    ResultComponent,
    IntelBarChart,
    InputSelect,
    ResultComponentL1
  },
  props: ['assessItem', 'showFeedback'],
  created() {
    const self = this;
  },
  data() {
    return {
      barStyles: {
        height: '150px',
        width: '505px'
      },
      definitions: ['Below Standard', 'Standard', 'Medium', 'High', 'Extreme'],
      hoveredItem: {
        left: 0,
        id: null,
        property_id: null
      },
    }
  },
  computed: {
    ...mapGetters({
      assessmentInfo: "assessment/assessmentInfo",
      assessmentModule: "assessment/assessmentModule",
      assessmentValues: "assessment/assessmentValues"
    }),
    getDefinitionRanges() {
      if(this.assessmentInfo.definition_id && this.assessmentInfo.definition && this.assessmentInfo.definition.items) {
        let definition = this.assessmentInfo.definition.items.find(definition => definition.type === 1);
        if(definition) {
          return definition.range_with_definition;
        }
      }
      return [];
    },
    properties() {
      let properties = this.assessmentModule.system_property?.property_collections
      return properties ? properties : []
    }
  },
  methods: {
    getL1Indexing(index, selfLevel) {
      let settings = this.assessmentModule.collection_settings;
      if(settings) {
        let collection = settings.collections[selfLevel];
        return parseFloat(collection.index_number) + index;
      }
      return '';
    },
    getSubLevelIndexing(index, currentData, selfLevel) {
      return currentData + '.' + (index + 1);
    },
    getScenarioCollection() {
      if(this.assessmentModule && this.assessmentModule.scenario_collections) {
        return this.assessmentModule.scenario_collections;
      }
      return [];
    },
    getPropertyWidth(item, pCollectionId) {
      return this.getPropertyOriginalValue(item, pCollectionId) * 10;
    },
    getPropertyAvgWidth(item, pCollectionId) {
      return this.getPropertyAvgValue(item, pCollectionId) * 10;
    },
    getPropertyOriginalValue(item, pCollectionId) {
      let value = 0;
      let pCollectionItem = item.properties?.find(pItem => parseInt(pItem.property_collection_id) === parseInt(pCollectionId));
      if (pCollectionItem) {
        value = parseFloat(pCollectionItem.sample_value);
      }
      return value.toFixed(2);
    },
    getPropertyAvgValue(item, pCollectionId) {
      let summation = 0;
      let assessProps = this.assessmentValues.filter(assessValue => parseInt(item.id) === parseInt(assessValue.scenario_collection_id) &&
          parseInt(pCollectionId) === parseInt(assessValue.property_collection_id));

      assessProps.filter(assessValue => {
        if (parseInt(item.id) === parseInt(assessValue.scenario_collection_id) &&
            parseInt(pCollectionId) === parseInt(assessValue.property_collection_id)) {
          summation += assessValue.sample_value;
        }
      });

      return assessProps.length > 0 ? parseFloat(summation / assessProps.length).toFixed(2) : summation.toFixed(2);
    },
    regularUpdate(assessmentInfo) {
      this.$store.dispatch("assessment/updateAssessment", assessmentInfo);
    },
    getStartedOrContribute() {
      this.$emit("moveToFeedback");
    },
    scrollToIdentifier(id) {
      let index = this.getScenarioCollection().findIndex(item => parseInt(item.id) === parseInt(id));
      if(index > 0) {
        let scrolledPos = 0;
        this.getScenarioCollection().forEach((item, iIndex) => {
          if(iIndex < index) {
            scrolledPos += $("#identifier_group_" + iIndex).height();
          }
        });

        $("#scrollDiv").animate({
          // scrollTop: scrolledPos + $("#assess_content").height() + (30 + (index + 1)) + (25 * (index + 1))
          scrollTop: scrolledPos
        }, 800);
      } else {
        $("#scrollDiv").animate({
          scrollTop: 0
        }, 800);
      }
    },
    getGroupChartData(module, item, selectedId) {
      let datasets = [
        {
          label: 'Scenario Properties',
          backgroundColor: '#C9C9C9',
          data: []
        },
        {
          label: 'Assessment Properties',
          backgroundColor: [],
          data: []
        }
      ];
      module.assess_properties.forEach(prop => {
        let sample_value = this.getPropertyOriginalValue(item, prop.id);
        sample_value = sample_value ? sample_value : 0;
        datasets[0].data.push(sample_value);

        let assess_property = item.assess_properties?.find(data => data.property_collection_id === prop.id);
        let assess_value = assess_property && assess_property.sample_value ? assess_property.sample_value : 0;
        datasets[1].data.push(assess_value.toFixed(2));
        let bgColor = this.getBg(assess_value.toFixed(2))
        if(selectedId && selectedId != prop.id) {
          bgColor = this.addOpacityToBg(bgColor, .5)
        }
        datasets[1].backgroundColor.push(bgColor);
      });

      return {
        labels: module.assess_properties.map(data => data.title),
        datasets: datasets,
      };
    },
    getBg(value) {
      let definition = this.assessmentInfo?.definition_style
      if(value > 0) {
        value = value - .000001;
      }
      value = Math.floor(value / 2);
      if(value > 4) {
        value = 4;
      }
      if(definition.length > value) {
        return definition[value].bg;
      }
      return '#0075FF'
    },
    addOpacityToBg(color, opacity) {
      let _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
      return color + _opacity.toString(16).toUpperCase();
    },
  },
}
</script>

<style scoped>
.value-track {
  background: #414141;
  width: 100%;
  height: 2px;
}
.progress-track {
  height: 2px;
  max-width: 100%;
}
.average-track {
  height: 2px;
  max-width: 100%;
}
.range {
  height: 2px;
  width: 100%;
  outline: none;
  -webkit-appearance: none;
}
input[type='range']::-webkit-slider-thumb {
  width: 30px;
  -webkit-appearance: none;
  height: 12px !important;
  cursor: pointer;
  background: #43738C;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 1px;
}
.z-1 {
  z-index: 1;
}
.assess-definition-title {
  width: 120px;
}
.hover-track {
  top: -25px;
}
</style>
