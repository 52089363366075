<template>
  <div class="relative flex flex-col items-center assess-right-h" :class="{'justify-center': comments.length <= 0}">
    <div class="comment-create-box mb-8 shadow-one bg-primary-three rounded" v-if="checkUser === 'assignee'">
      <textarea class="fs-14 bg-transparent font-poppins px-6 py-4 textarea bld-content-area placeholder-secondary-two text-primary-one"
                :placeholder="comment.placeholder" @click="comment.placeholder = ''" v-model="comment.text" v-click-outside="hideCommentPlaceholder"></textarea>
      <div class="flex justify-start ml-6">
        <button class="flex justify-center items-center fs-12 font-poppins fw-600" @click="storeComment()">Submit</button>
      </div>
    </div>
    <div class="comments-sections-author w-full overflow-y-auto" :class="{'comments-sections-responder': checkUser === 'assignee'}">
      <div class="bg-primary-three shadow-one rounded w-full p-5" v-for="(item, index) in getComments" :key="index" :class="{'comment-item-mt': index > 0}">
        <div class="flex items-center">
          <img src="/images/icons/assessment/assessment_demo_avatar.png" alt="image"
               class="assessment-commentor">
          <div class="ml-3">
            <h2 class="fs-14 text-primary-one fw-600 font-poppins">{{ getCommentor(item.created_by) }}</h2>
            <h2 class="fs-12 text-secondary-one font-poppins">
              {{ $lodash.ago(item.created_at) }}
            </h2>
          </div>
        </div>
        <p class="fs-14 font-poppins text-secondary-one-text mt-2">
          {{ item.comment }}
        </p>
      </div>
      <div class="no-items" v-if="comments.length <= 0">
        <div class="flex justify-center flex-col">
          <img src="/images/icons/assessment/none.svg" alt="image" class="no-items-img text-center">
          <h2 class="fs-20 font-poppins text-secondary-one mt-2 i-n-ml-20">No comments found</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Comments",
  props: ['assessItem'],
  created() {
    const self = this;
    self.$Progress.start();
    self.$store.dispatch("assessment/getComments", {
      assessment_id: self.$route.params.assessment_id
    }).then(response => {
      self.$Progress.finish();
    });
  },
  data() {
    return {
      comment: {
        placeholder: 'Write your comment here',
        text: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      comments: "assessment/comments",
      assessmentInfo: "assessment/assessmentInfo"
    }),
    getAuthUser() {
      let user = JSON.parse(localStorage.getItem('_user'));
      if (user) {
        return user;
      }
      return null;
    },
    checkUser() {
      let user = null;
      if (this.assessmentInfo && this.getAuthUser) {
        if (parseInt(this.assessmentInfo.created_by) === parseInt(this.getAuthUser.id)) {
          user = 'author';
        } else if (this.assessmentInfo.assignee.find(el => parseInt(el.assignee_id) === parseInt(this.getAuthUser.id))) {
          user = 'assignee';
        }
      }

      return user;
    },
    getComments() {
      let userId = this.getAuthUser ? this.getAuthUser.id : '';
      let comments = this.comments;
      if(this.checkUser === 'assignee') {
        comments = comments.filter(item => parseInt(item.created_by.id) === parseInt(userId));
      }
      if(this.assessItem) {
        return comments.filter(item => parseInt(item.scenario_collection_id) === parseInt(this.assessItem.id));
      }
      return comments;
    }
  },
  methods: {
    getCommentor(user) {
      if(user) {
        let name = user.first_name ? user.first_name : '';
        if(user.last_name) {
          name = name + ' ' + user.last_name;
        }
        return name;
      }
      return 'N/A'
    },
    hideCommentPlaceholder() {
      this.comment = {
        placeholder: 'Write your comment here',
        text: ''
      }
    },
    storeComment() {
      if(this.comment.text) {
        this.$Progress.start();
        this.$store.dispatch("assessment/storeComment", {
          comment: this.comment.text,
          assessment_id: this.$route.params.assessment_id,
          scenario_collection_id: this.assessItem ? this.assessItem.id : ''
        }).then(response => {
          this.$Progress.finish();
        });
      }
    }
  }
}
</script>

<style scoped>
.a5 {
  color: #a5a5a5;
}

.assessment-comments {
  margin-top: 20px;
}

.comments-sections-author {
  height: 785px;
}

.comments-sections-responder {
  height: 540px;
}

.assessment-comment {
  padding-bottom: 25px;
  border-bottom: 1px solid #414141;
}

.assessment-commentor {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.comment-item-mt {
  margin-top: 25px;
}

.no-items {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translate(-33%, -50%);
  -ms-transform: translate(-33%, -50%);
}

.on-items-img {
  width: 56px;
  height: 56px;
  margin-left: 55px;
}
.comment-create-box {
  width: 100%;
  height: 215px;
}
.textarea {
  height: 170px;
  width: 100%;
}
.no-items-img {
  width: 56px;
  height: 56px;
  margin-left: 48px;
}
</style>
