import { Bar } from 'vue-chartjs'
export default {
  name: "IntelBarChart",
  extends: Bar,
  props: ['chartData', 'breakTheTitle'],
  mounted () {
    this.renderBarChart();
  },
  methods: {
    renderBarChart() {
      const self = this;
      this.renderChart(this.chartData, {
        title: {
          display: false,
          text: 'Instart360'
        },
        labels: {
          autoRotation: false
        },
        tooltips: {
          mode: 'index',
          intersect: false
        },
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 10
            }
        },
        backgroundColor: 'red',
        responsive: true,
        maintainAspectRatio: false,
        onClick: this.handle,
        chartArea: {
          backgroundColor: '#F9F9F9'
        },
        scales: {
          yAxes: [{
            gridLines: {
              drawBorder: true,
              color: '#E2E2E2',
              zeroLineWidth: 1,
              zeroLineColor: '#E2E2E2',
              tickLength: 0,
            },
            ticks: {
              min: 0,
              max: 10,
              maxTicksLimit: 10,
              fontColor: "#3D3D3D",
              beginAtZero: false,
              display: false,
              padding: 0,
              crossAlign: 'far'
            },
            labels: {
              fontFamily: "'Poppins', sans-serif",
              fontSize: 16
            },
          }],
          xAxes: [{
            // afterFit: (axis) =>{
            //   axis.paddingLeft = 25;
            //   axis.paddingRight = 15;
            // },
            // stacked: true,
            gridLines: {
              drawBorder: true,
              display: true,
              color: '#E2E2E2',
              borderWidth: 0,
              zeroLineColor: '#E2E2E2',
              tickLength: 0,
            },
            ticks: {
              fontColor: "#3D3D3D",
              beginAtZero: true,
              fontSize: 10,
              crossAlign: 'far',
              lineHeight: 0.1,
              maxRotation: 0,
              callback: function(val, index) {
                return [self.chartData && self.chartData.indexing && self.chartData.indexing[0] ? self.chartData.indexing[index] : '', ( self.breakTheTitle && val.length > 15 ) ? val.substr(0, 15) + '...' : val];
              },
            },
            barPercentage: 0.8,
          }],
        },
        legend: {
          display: false
        },
        hover: {
          mode: 'index',
        },
        plugins: {
          beforeDraw: (chart) => {
            const ctx = chart.canvas.getContext('2d');
            ctx.save();
            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillStyle = 'lightGreen';
            ctx.fillRect(0, 0, chart.width, chart.height);
            ctx.restore();
          },
        }
      })
    },
    handle(point, event) {
      if(event && event[0] && event[0]._index >= 0) {
        this.$emit("loadSubLevelData", event[0]._index);
      }
    }
  },
  watch: {
    chartData: function (val) {
      this.renderBarChart();
    },
  },
}