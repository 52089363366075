<template>
    <div class="flex">
        <div class="w-full">
            <div class="w-full flex items-center">
                <span class="fs-10 fw-400 text-primary-one mr-2">
                  {{ assessmentInfo && assessmentInfo.property && assessmentInfo.property.title ? assessmentInfo.property.title : 'N/A' }}
                </span>
                <ChevronSolidIcon class="w-2 h-2 text-primary-one mr-4" />
                <InputSelect
                    v-model="selectedPropertyId"
                    label="Select Property"
                    :items="propertyCollection"
                    :label-classes="'text-secondary-two fs-14 fw-500'"
                    :classes="'px-1 rounded-sm fs-10 fw-400 text-primary-one bg-transparent inst-dropdown param-dropdown'"
                />
            </div>
            <p class="text-primary-one fs-12 fw-800 mt-2">{{ selectedProperty ? selectedProperty.title : '' }}</p>
            <p class="text-primary-one fs-14 fw-500 mt-4">{{ definitionTitle }}</p>
            <p class="text-primary-one fs-12 fw-500">{{ definitionDescription }}</p>
        </div>
        <div>
            <slot :selectedPropertyId="selectedPropertyId" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import InputSelect from '@/components/assessment/survey/InputSelect.vue'
export default {
    name: 'ResultComponent',
    components: {
        InputSelect,
    },
    data: () => ({
        selectedPropertyId: ''
    }),
    props: {
        parent: Object,
        properties: Array,
        getPropertyOriginalValue: Function
    },
    computed: {
        ...mapGetters({
            assessmentInfo: "assessment/assessmentInfo",
            assessmentModule: "assessment/assessmentModule"
        }),
        propertyCollection() {
            let properties = this.assessmentModule.system_property?.property_collections
            return properties ? properties : []
        },
        selectedProperty() {
            return this.assessmentModule.system_property?.property_collections.find(item => item.id == this.selectedPropertyId)
        },
        definitionTitle() {
            return this.assessmentInfo.definition?.title
        },
        definitionDescription() {
            return this.assessmentInfo.definition?.description
        },
    },
    methods: {

    },
    mounted() {
        this.$nextTick(() => {
            if(this.propertyCollection.length) {
                this.selectedPropertyId = this.propertyCollection[0].id
            }
        })
    }
}
</script>
