<template>
  <div class="assess-last-layer ml-10 rounded assess-root-box">
    <div class="assess-last-layer-headings flex justify-between bg-primary-three shadow-one rounded mb-8">
      <h2 class="fs-14 fw-700 font-poppins text-secondary-one opacity-60 cursor-pointer" @click="toggleActiveMode(1)"
          :class="{'opacity-100': activeMode === 1}">Ideas</h2>
      <h2 class="fs-14 fw-700 font-poppins text-secondary-one opacity-60 cursor-pointer" @click="toggleActiveMode(2)"
          :class="{'opacity-100': activeMode === 2}">Comments</h2>
    </div>
    <div>
      <Insight :assessItem="assessItem" v-if="activeMode === 1"/>
      <Comment :assessItem="assessItem" v-if="activeMode === 2"/>
    </div>
  </div>
</template>

<script>
import Insight from "./Insight";
import Comment from "./Comment";

export default {
  name: "FeedbackRight",
  props: ['assessItem'],
  components: {Comment, Insight},
  data() {
    return {
      activeMode: 1
    }
  },
  methods: {
    toggleActiveMode(value) {
      this.activeMode = value;
    }
  }
}
</script>

<style scoped>
.feedback-right {
  background: #232326;
  border-radius: 4px;
  width: 410px;
  margin-left: 20px;
  height: 890px;
  overflow-y: auto;
}

.feedback-right-buttons {
  padding: 22px 0;
  border-bottom: 1px solid #414141;
  margin: 0 30px;
}

.feedback-right-buttons-ml {
  margin-left: 50px;
}

.feedback-components {
  margin: 20px 0;
  padding: 0 30px;
}
</style>
