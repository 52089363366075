<template>
    <div class="flex gap-4">
        <div class="space-y-5">
            <div
                v-for="(mProperty, mPIndex) in properties" 
                :key="mPIndex"
                class="flex items-center"
            >   
                <h2 
                    @click="selectedProperty = mProperty"
                    :class="[selectedProperty.id == mProperty.id ? 'fw-600' : 'fw-400']"
                    class="w-40 truncate font-poppins fs-12 text-primary-one cursor-pointer"
                >{{ mProperty ? mProperty.title : '' }}</h2>
                <p 
                    :class="[selectedProperty.id == mProperty.id ? 'fw-600' : 'fw-400']"
                    class="fs-14 text-primary-one w-8 i-mr-30">{{ getPropertyAvgValue(parent, mProperty.id) }}</p>
                <p
                    :class="[selectedProperty.id == mProperty.id ? 'fw-600' : 'fw-400']"
                    class="fs-12 fw-400 text-primary-one w-56 truncate">
                    {{ getPropertyDef(getPropertyAvgValue(parent, mProperty.id)) }}
                </p>
            </div>
        </div>
        <div>
            <slot :selectedPropertyId="selectedProperty.id" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: 'ResultComponent',
    data: () => ({
        selectedProperty: {}
    }),
    props: {
        parent: Object,
        properties: Array,
        getPropertyAvgValue: Function
    },
    computed: {
        ...mapGetters({
            assessmentInfo: "assessment/assessmentInfo",
        }),
        getDefinitionRanges() {
            if(this.assessmentInfo.definition_id && this.assessmentInfo.definition && this.assessmentInfo.definition.items) {
                let definition = this.assessmentInfo.definition.items.find(definition => definition.type === 1);
                if(definition) {
                return definition.range_with_definition;
                }
            }
            return [];
        }
    },
    methods: {
        getPropertyDef(value) {
            let definition = this.getDefinitionRanges
            if(value > 0) {
                value = value - .000001;
            }
            value = Math.floor(value / 2);
            if(value > 4) {
                value = 4;
            }
            if(definition.length > value) {
                return definition[value].definition;
            }
            return ''
        },
    }
}
</script>