import { render, staticRenderFns } from "./RightPart.vue?vue&type=template&id=de6bb5f6&scoped=true&"
import script from "./RightPart.vue?vue&type=script&lang=js&"
export * from "./RightPart.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de6bb5f6",
  null
  
)

export default component.exports