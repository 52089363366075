<template>
  <div style="height: 1080px" class="_main_wrapper relative flex">
    <left-part class="i-left-panel shadow-one"
      :showFeedback="showFeedback"
      :user-type="checkUser"
      @toggleAssessItem="toggleAssessItem"
      @moveToCover="moveToCover"
      @moveToFeedback="moveToFeedback"
      :ref="'assessment_left'"
    />
    <div class="i-right-panel">
      <div class="pl-10 relative flex i-pb-19 i-mt-45 justify-between pr-16">
        <div>
          <div class="assess-menu-box" v-if="showFeedback">
            <div class="flex items-center" v-if="checkUser && checkUser === 'author'">
              <div v-for="(period, tPIndex) in timePeriods"
                  :key="tPIndex"
                  class="bg-primary-three fs-12 px-3 py-2 cursor-pointer shadow-one rounded-sm flex items-center justify-center font-poppins"
                  @click="toggleSearchDatePeriod(period)"
                  :class="{'i-ml-10': tPIndex > 0, 'text-primary-three bg-primary-four': searchObj.created_at === period, 'text-secondary-one': searchObj.created_at !== period}">
                {{ period }}
              </div>
              <div class="relative flex i-ml-10">
                <datepicker ref="programaticOpen" class="bg-primary-three px-3 py-1 rounded-sm shadow-one text-secondary-one"
                  :input-class="'assess-input-picker'"
                  placeholder="dd-mm-yyyy"
                  v-model="searchObj.created_at"
                  @selected="setPickerDate"
                  :format="'dd-MM-yyyy'"
              />
              </div>
              <div class="bg-primary-three px-3 py-2 cursor-pointer relative rounded-sm shadow-one flex items-center justify-center i-ml-10">
                <h2 class="fs-12 font-poppins text-secondary-one">Participant</h2>
              </div>
              <select class="bg-transparent fs-14 font-poppins text-primary-one pr-select-dropdown d-none"
                      v-model="searchObj.created_by" @change="filterValue()">
                <option value="null" disabled selected>Select Participant</option>
                <option v-for="(user, uIndex) in getParticipants" :key="uIndex" :value="user.assignee_id">
                  {{ getUserName(user) }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <WorkspaceMenu2 class="-i-mt-10"/>
      </div>
      <!-- <button @click="myFunc(assessmentModule)">get chart data</button> -->
      <right-part
        ref="assess_right_part"
        :assessItem="assessItem"
        :showFeedback="showFeedback"
        @moveToFeedback="moveToFeedback"
        :chartData="chartData"
      />
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import LeftPart from "../../../components/assessment/survey/LeftPart";
import RightPart from "../../../components/assessment/survey/RightPart";
import Datepicker from 'vuejs-datepicker';
import WorkspaceMenu2 from '@/components/InstMenu/WorkspaceMenu2.vue'

export default {
  name: "AssessmentSurvey",
  components: {
    LeftPart, RightPart, Datepicker, WorkspaceMenu2
  },

  created() {
    document.title = 'Assessment Review';
    const self = this;
    self.$store.dispatch("assessment/setWorkingAssessment", null);
    self.$Progress.start();
    self.$store.dispatch("workspace/projectSelection", self.$route.params.id);
    this.$store.dispatch("project/getSolidObject", this.$route.params.id);
    self.$store.dispatch("assessment/getScenarioCollection", {
      assessment_id: self.$route.params.assessment_id
    }).then(response => {
      // console.log(this.assessmentModule.scenario_collections, 'after response')
      let user = null;
      if (this.assessmentInfo && this.getAuthUser) {
        if (parseInt(this.assessmentInfo.created_by) === parseInt(this.getAuthUser.id)) {
          user = 'author';
        } else if (this.assessmentInfo.assignee.find(el => parseInt(el.assignee_id) === parseInt(this.getAuthUser.id) && parseInt(el.participation_status) === 1)) {
          user = 'assignee';
        }
        this.checkAccessability(user);
      }
      self.filterValue();
      self.$Progress.finish();
    });
  },

  data() {
    return {
      serverMode: process.env.NODE_ENV,
      assessItem: null,
      timePeriods: ['1w', '1m', '3m', '6m', 'YTD', 'All'],
      searchObj: {
        created_by: null,
        location: null,
        function: null,
        created_at: 'All'
      },
      showFeedback: false,
      chartData: {
        datasets: [],
        labels: []
      }
    }
  },
  computed: {
    ...mapGetters({
      modules: "programmatic/projectModules",
      assessmentInfo: "assessment/assessmentInfo",
      assessmentModule: "assessment/assessmentModule",
      assessmentValues: "assessment/assessmentValues",
      assessmentResponderValues: "assessment/assessmentResponderValues",
    }),
    // chartData() {
    //   let val
    //   if('scenario_collections' in this.assessmentModule) {
    //     if(this.assessmentModule.scenario_collections[0].assess_properties) {
    //       console.log(this.assessmentModule.scenario_collections[0].assess_properties, 'fdfd module')
    //       val = this.getModuleChartData(this.assessmentModule)
    //     }
    //   }
    //   return val
    // },
    getAuthUser() {
      let user = JSON.parse(localStorage.getItem('_user'));
      if (user) {
        return user;
      }
      return null;
    },
    checkUser() {
      let user = null;
      if (this.assessmentInfo && this.getAuthUser) {
        if (parseInt(this.assessmentInfo.created_by) === parseInt(this.getAuthUser.id)) {
          user = 'author';
        } else if (this.assessmentInfo.assignee.find(el => parseInt(el.assignee_id) === parseInt(this.getAuthUser.id) && parseInt(el.participation_status) === 1)) {
          user = 'assignee';
        }
        this.checkAccessability(user);
      }
      return user;
    },
    getParticipants() {
      return this.assessmentInfo.assignee.filter(item => item.participation_status);
    }
  },
  methods: {
    // myFunc(module) {
    //   this.chartData = this.getModuleChartData(module)
    // },
    toggleAssessItem(item) {
      this.assessItem = item;
    },
    sendScreen() {
      this.$store.dispatch("assessment/setWorkingAssessment", this.$route.params.assessment_id);
      this.$router.push({name: 'intel.assess', params: {id: this.$route.params.id}});
    },
    getScenarioCollection() {
      if(this.assessmentModule && this.assessmentModule.scenario_collections) {
        return this.assessmentModule.scenario_collections;
      }
      return [];
    },
    checkAccessability(user) {
      if (!user) {
        this.$router.push({name: 'workspace_dashboard'});
      }
    },
    filterValue() {
      this.searchObj.assessment_id = this.$route.params.assessment_id;
      this.searchObj.childs = this.getChildIds();
      this.$store.dispatch("assessment/getAssessmentFilteredValue", this.searchObj).then(response => {
        let properties = this.assessmentModule && this.assessmentModule.assess_properties ? this.assessmentModule.assess_properties : [];
        if(properties) {
          this.getAverage(this.getScenarioCollection(), this.assessmentValues, properties);
          this.getModuleScenarioAverage(this.assessmentModule);
          let ss = this.getModuleChartData(this.assessmentModule)
          this.chartData = ss
        }
      });
    },

    getChildIds() {
      const self = this;
      self.ids = [];
      self.getScenarioCollection().forEach(item => {
        self.ids.push(item.id);
        self.pushChildIds(item);
      });
      return self.ids;
    },
    pushChildIds(data) {
      const self = this;
      data.child.forEach(item => {
        self.ids.push(item.id);
        self.pushChildIds(item);
      });
    },
    getAverage(data, assessmentValues, properties) {
      properties.forEach(prop => {
        let sum = 0;
        this.assessmentModule.averages = this.assessmentModule && this.assessmentModule.averages && this.assessmentModule.averages.length !== properties.length ? this.assessmentModule.averages : [];
        data.forEach(item => {
          sum += this.getChildAverage(item, assessmentValues, prop, properties);
        });

        let avg = sum > 0 ? sum / data.length : 0;
        this.pushToValueArray(this.assessmentModule.averages, prop, avg);
      });
    },
    getChildAverage(data, assessmentValues, prop, properties) {
      if(data.child && data.child.length > 0) {
        let sum = 0;
        data.assess_properties = data.assess_properties && data.assess_properties.length !== properties.length ? data.assess_properties : [];
        data.child.forEach(item => {
          sum += this.getChildAverage(item, assessmentValues, prop, properties);
        });

        let avg = sum > 0 ? sum / data.child.length : 0;
        this.pushToValueArray(data.assess_properties, prop, avg, data);

        return avg;
      } else {
        let avg = this.getPropertyAvgValue(data, prop.id, assessmentValues);
        data.assess_properties = data.assess_properties ? data.assess_properties : [];
        this.pushToValueArray(data.assess_properties, prop, avg, data);
        return avg;
      }
    },

    getPropertyAvgValue(item, pCollectionId, assessmentValues) {
      let summation = 0;
      let assessProps = assessmentValues.filter(assessValue => parseInt(item.id) === parseInt(assessValue.scenario_collection_id) &&
          parseInt(pCollectionId) === parseInt(assessValue.property_collection_id));

      assessProps.filter(assessValue => {
        if (parseInt(item.id) === parseInt(assessValue.scenario_collection_id) &&
            parseInt(pCollectionId) === parseInt(assessValue.property_collection_id)) {
          summation += assessValue.sample_value;
        }
      });

      return assessProps.length > 0 ? parseFloat(summation / assessProps.length) : summation;
    },

    pushToValueArray(data, property, value, item = null) {
      data.push({
        property_collection_id: property.id,
        scenario_collection_id: item && item.id ? item.id : null,
        sample_value: value ? value : 0
      });
    },

    getModuleScenarioAverage(module) {
      module.properties = [];
      let parameters = module.assess_properties;
      if(parameters && parameters.length > 0) {
        parameters.forEach(param => {
          let parameterSampleValue = 0;
          module.scenario_collections.forEach(item => {
            let paramObj = item.properties?.find(paramValue => paramValue.property_collection_id === param.id);
            if(paramObj) {
              parameterSampleValue += paramObj.sample_value;
            }
          });
          module.properties.push({
            module_id: module.id,
            property_collection_id: param.id,
            sample_value: parameterSampleValue > 0 ? parameterSampleValue / module.scenario_collections.length : 0
          });
        });
      }
    },
    getModuleChartData(module) {
    //  if(!module.assess_properties) return
      let datasets = [];
      // let labels = [];
      module.assess_properties.forEach(prop => {
        let obj = {
          label: prop.title,
          backgroundColor: prop.bg_color ? prop.bg_color : '#2B80AD',
          data: []
        }

        module.scenario_collections.forEach(item => {
          obj.data.push(this.getPropertyValue(item, prop.id));
        });
        datasets.push(obj);
      });
      return {
        labels: module.scenario_collections.map(item => item.title),
        datasets: datasets,
      };
    },

    getPropertyValue(item, propertyId) {
      // console.log(item, propertyId , 'getPropertyValue')
      let value = 0;
      let property = item.assess_properties?.find(data => data.property_collection_id === propertyId);
      if(property) {
        value = property.sample_value;
      }
      return value.toFixed(2);
    },

    getUserName(user) {
      let name = '';
      if (user && user.assignee && user.assignee.first_name) {
        name = user.assignee.first_name;
        if (user.assignee.last_name) {
          name = name + ' ' + user.assignee.last_name;
        }
        return name;
      }
      name = user.email;
      return name;
    },
    setPickerDate(value) {
      this.searchObj.created_at = this.$lodash.format(value, 'yyyy-MM-dd');
      this.filterValue();
    },
    togglePicker() {
      this.$refs.programaticOpen.showCalendar();
    },
    loadAssessmentValues() {
      const self = this;
      self.$store.dispatch("assessment/getAssessmentValueForCreator", {
        assessment_id: self.$route.params.assessment_id,
        childs: self.getChildIds()
      });
    },
    loadAssessmentValuesForResponder() {
      this.$store.dispatch("assessment/getAssessmentValueForResponder", {
        assessment_id: this.$route.params.assessment_id
      }).then(response => {
        if(response && response.data && response.data.status) {
          this.$refs.assess_right_part.prepareResponderAverage();
        }
      });
    },
    toggleSearchDatePeriod(period) {
      this.searchObj.created_at = period;
      this.filterValue();
    },
    moveToCover() {
      this.showFeedback = false;
      this.assessItem = null;
      this.$refs.assessment_left.emptyActive();
    },
    moveToFeedback() {
      this.showFeedback = true;
      if(this.checkUser === 'assignee') {
        this.loadAssessmentValuesForResponder();
      }
    }
  },
  watch: {
    assessItem: function (val) {
      if (this.checkUser === 'author') {
        if (this.searchObj.created_by || this.searchObj.created_at || this.searchObj.location || this.searchObj.function) {
          this.filterValue();
        } else {
          this.loadAssessmentValues();
        }
      }
    },

  }
}
</script>

<style scoped>
.assess-major-part {
  padding: 30px;
}
</style>
