<template>
  <div class="relative flex flex-col items-center assess-insight-h overflow-y-auto" :class="{'justify-center': insights.length <= 0}">
    <div class="rounded p-6 bg-primary-three shadow-one" v-for="(insight, index) in getInsights" :key="index" :class="{'mt-8': index > 0}">
      <input class="fw-700 text-secondary-one w-full fs-16 font-poppins bg-transparent" type="text"
             placeholder="Headline" v-model="insight.title" @change="update(insight)">
      <textarea class="text-secondary-one font-poppins fs-14 w-full bg-transparent bld-content-area desc mb-1 mt-1"
                placeholder="Sample description" v-model="insight.description" @change="update(insight)"></textarea>
      <span
          class="flex mb-5 cursor-pointer fs-14 font-poppins text-secondary-one pb-5 i-border-b-1 border-solid border-secondary-five"><img
          class="mr-2" src="/images/icons/assessment/attach.svg" alt="icon" />Attach</span>
      <div class="mb-5 flex items-center" v-for="(property, pindex) in insight.properties" :key="pindex">
        <input class="parameter-title fs-14 font-poppins text-secondary-one bg-transparent" type="text"
               placeholder="Headline" v-model="property.title" @change="update(insight)">
        <div class="insight-slider">
          <div class="track-box relative">
            <input class="range absolute left-0 top-0 cursor-pointer bg-secondary-five" min="0" max="10" step=".1"
                   type="range" v-model="property.value" @change="update(insight)">
            <div class="progress-track absolute left-0 top-0 bg-primary-five"
                 :style="{width: property.value*10 + '%'}"></div>
            <div class="absolute value-tip hidden">{{ property.value }}</div>
          </div>
        </div>
        <img class="cursor-pointer ml-2 insight-prop-minus" @click="deleteProp(insight, pindex)"
             src="/images/icons/properties/off_outline_close.svg" alt="icon" />
      </div>
      <div class="flex justify-between items-center" v-if="checkUser === 'assignee'">
        <span class="cursor-pointer text-primary-one font-poppins fw-600 fs-14" @click="newProperty(insight)">+ Add property</span>
        <div class="flex items-center">
          <span class="cursor-pointer text-primary-one font-poppins fw-600 fs-14" @click="newInsight()">+ New Idea</span>
          <img class="cursor-pointer ml-2 insight-prop-minus" @click="deleteInsight(insight.id)"
               src="/images/icons/properties/off_outline_close.svg" alt="icon" />
        </div>
      </div>
    </div>
    <div class="no-items" v-if="getInsights.length <= 0">
      <div class="flex justify-center flex-col">
        <img src="/images/icons/assessment/none.svg" alt="image" class="no-items-img text-center">
        <h2 class="fs-20 font-poppins text-secondary-one mt-2 ml-2">No ideas found</h2>
        <div class="flex items-center justify-center fs-14 text-primary-one bg-primary-three i-pl-35 i-pr-35 py-2 h-10 fw-600 mt-2 shadow-one rounded cursor-pointer hover:bg-secondary-five" @click="newInsight()">+ New Idea</div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Insight",
  props: ['assessItem'],
  created() {
    const self = this;
    self.$Progress.start();
    self.$store.dispatch("assessment/getInsights", {
      assessment_id: self.$route.params.assessment_id
    }).then(response => {
      self.$Progress.finish();
    });
  },
  data() {
    return {
      // insight: {
      //     title: '',
      //     description: '',
      //     properties: ''
      // }
    }
  },
  computed: {
    ...mapGetters({
      insights: "assessment/insights",
      assessmentInfo: "assessment/assessmentInfo"
    }),
    getAuthUser() {
      let user = JSON.parse(localStorage.getItem('_user'));
      if (user) {
        return user;
      }
      return null;
    },
    checkUser() {
      let user = null;
      if (this.assessmentInfo && this.getAuthUser) {
        if (parseInt(this.assessmentInfo.created_by) === parseInt(this.getAuthUser.id)) {
          user = 'author';
        } else if (this.assessmentInfo.assignee.find(el => parseInt(el.assignee_id) === parseInt(this.getAuthUser.id))) {
          user = 'assignee';
        }
      }

      return user;
    },
    getInsights() {
      let userId = this.getAuthUser ? this.getAuthUser.id : '';
      let insights = this.insights;
      if(this.checkUser === 'assignee') {
        insights = insights.filter(item => parseInt(item.created_by) === parseInt(userId));
      }
      if(this.assessItem) {
        return insights.filter(item => parseInt(item.scenario_collection_id) === parseInt(this.assessItem.id));
      }
      return insights;
    }
  },
  watch: {},
  methods: {
    newInsight() {
      this.$Progress.start();
      let obj = {}
      obj.title = "Sample Title"
      obj.assessment_id = this.$route.params.assessment_id
      obj.scenario_collection_id = this.assessItem ? this.assessItem.id : '';
      this.$store.dispatch("assessment/storeInsight", obj)
          .then(response => {
            this.$Progress.finish();
          });
    },
    newProperty(insight) {
      this.$Progress.start();
      let newProp = {title: 'Sample Title', value: 0}
      insight.properties.push(newProp)
      this.$store.dispatch("assessment/updateInsight", insight)
          .then(response => {
            this.$Progress.finish();
          });
    },
    update(insight) {
      this.$Progress.start();
      this.$store.dispatch("assessment/updateInsight", insight)
          .then(response => {
            this.$Progress.finish();
          });
    },
    deleteInsight(id) {
      this.$Progress.start();
      this.$store.dispatch("assessment/deleteInsight", {id: id, assessment_id: this.$route.params.assessment_id})
          .then(response => {
            this.$Progress.finish();
          });
    },
    deleteProp(insight, index) {
      this.$Progress.start();
      insight.properties.splice(index, 1)
      this.$store.dispatch("assessment/updateInsight", insight)
          .then(response => {
            this.$Progress.finish();
          });
    }
  }
}
</script>

<style scoped>
.insight-prop-minus {
  width: 16px;
  height: 16px;
}
.insight-minus {
  width: 20px;
  height: 20px;
}
.grey {
  color: #787878;
}

.c41 {
  color: #414141;
}

.insight-input-box, .add-button-div {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  background: #2A2A2D;
  box-shadow: 0px 12px 30px -15px rgba(0, 0, 0, 0.5);
  border-radius: 4px;

}

.insight-input-box {
  margin-bottom: 30px;
  font-size: 14px;
}

.add-button-div {
  border: 1px dashed #414141;
}

.input-div {
  border-bottom: 1px solid #414141;
  margin-bottom: 30px;
}

.title {
  background: transparent;
  margin-bottom: 8px;
}

.desc {
  height: 70px;
}

.title::placeholder, .desc::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #787878;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #787878;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #787878;
}

.insight-body {
  padding-bottom: 10px;
}

.insight-slide-set {
  color: #a5a5a5;
  margin-bottom: 20px;
}

.insight-slide-set:hover .value-tip {
  display: inline;
  left: 50%;
  top: -24px;
}

.parameter-title {
  width: 127px;
}

.insight-slider {
  width: 153px;
}

.track-box {
  width: 153px;
}

.value-track {
  width: 100%;
  height: 2px;
}

.progress-track {
  height: 2px;
}

.range {
  height: 2px;
  width: 100%;
  outline: none;
  -webkit-appearance: none;
}

input[type='range']::-webkit-slider-thumb {
  width: 20px;
  -webkit-appearance: none;
  height: 10px !important;
  cursor: pointer;
  background: #00A7FE;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
  border-radius: 1px;
}

.no-items {
  margin: 0;
  position: absolute;
  top: 397px;
  left: 45%;
  transform: translate(-33%, -50%);
  -ms-transform: translate(-33%, -50%);
}

.no-items-img {
  width: 56px;
  height: 56px;
  margin-left: 48px;
}
</style>
