<template>
    <div class="space-y-2 pb-4">
        <div
            v-for="(mProperty, mPIndex) in items" 
            :key="mPIndex"
           
        >
        <div class="flex items-center">
            <h2 class="w-40 truncate fw-400 font-poppins fs-12 text-primary-one">{{ mProperty.title }}</h2>
            <img class="mr-6"  src="/images/line.png" alt="">
            
            <div style="width: 332px" class="relative mr-8 i-h-30">
                <div class="survey-progress-bg absolute border border-secondary-four rounded-sm"></div>
                <div class="tracker-bg absolute bg-secondary-three border border-secondary-five i-h-10"></div>
                <div 
                    class="absolute survey-progress-track"
                    :id="'progress-track-'+parent.id+'-'+mProperty.id"
                    :style="{'width': getPropertyReviewValue(parent.id, mProperty.id)*10+'%', background: getBg(getPropertyReviewValue(parent.id, mProperty.id))}"
                ></div>
                <input
                    min="0"
                    max="10"
                    step=".1"
                    type="range"
                    class="survey-range cursor-pointer bg-secondary-three border border-secondary-four absolute"
                    :id="'value-track-'+parent.id+'-'+mProperty.id"
                    :value="getPropertyReviewValue(parent.id, mProperty.id)"
                    @input="updateProgress($event, parent.id, mProperty.id)"
                    @change="updateReview($event, parent.id, mProperty.id)"
                />
                
            </div>
            <input
                v-limit
                @input="updateReview($event, parent.id, mProperty.id)"
                :value="getPropertyReviewValue(parent.id, mProperty.id)"
                min="0"
                max="10"
                class="i-h-30 i-w-50 border border-gray-300 flex items-center fs-14 fw-500 text-center mr-8" 
                type="number"

            >
            <p class="fs-12 fw-500 w-56 truncate">
                {{ getPropertyDef(getPropertyReviewValue(parent.id, mProperty.id)) }}
            </p>
        </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
export default {
    name: 'Survey',
    props: {
        parent: Object,
        items: Array,
        updateProgress: Function,
        updateReview: Function,
    },
    computed: {
         ...mapGetters({
            assessmentValues: "assessment/assessmentResponderValues",
            assessmentInfo: "assessment/assessmentInfo",
        }),
        getDefinitionRanges() {
            if(this.assessmentInfo.definition_id && this.assessmentInfo.definition && this.assessmentInfo.definition.items) {
                let definition = this.assessmentInfo.definition.items.find(definition => definition.type === 1);
                if(definition) {
                return definition.range_with_definition;
                }
            }
            return [];
        }
    },
    methods: {
        getPropertyReviewValue(scenario_collection_id, property_collection_id) {
            let valueData = this.assessmentValues.find(
                el => el.scenario_collection_id == scenario_collection_id
                && el.property_collection_id == property_collection_id
            )
            let value = valueData ? valueData.sample_value : 0
            return value
        },
        getPropertyDef(value) {
            let definition = this.getDefinitionRanges
            if(value > 0) {
                value = value - .000001;
            }
            value = Math.floor(value / 2);
            if(value > 4) {
                value = 4;
            }
            if(definition.length > value) {
                return definition[value].definition;
            }
            return ''
        },
        getBg(value) {
            let definition = this.assessmentInfo?.definition_style
            if(value > 0) {
                value = value - .000001;
            }
            value = Math.floor(value / 2);
            if(value > 4) {
                value = 4;
            }
            if(definition.length > value) {
                return definition[value].bg;
            }
            return '#0075FF'
        },
        updatePropertyValue(e, p) {
        }
    },
    directives: {
        limit: {
            bind: function (el) {
                el.addEventListener('input', () => {
                    if(el.value > 10) {
                        el.value = 10
                    }
                })
            }
        }
    }
}
</script>

<style>
.survey-range {
    top: 9px;
    height: 10px;
    width: 332px;
    outline: none;
    opacity: 0;
    -webkit-appearance: none;
}
.survey-progress-track {
    height: 10px;
    left: 9px;
    top: 10px;
}
.tracker-bg {
    top:10px;
    width: 332px;
    left: 9px;
}
.survey-progress-bg {
    width: 350px;
    height: 30px;
}
</style>