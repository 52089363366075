<template>
    <div class="flex items-center">
        <div class="flex items-center gap-x-8">
            <div class="relative ws-avatar-menu" @click="showAccountDropdown()"
                v-click-outside="hideAccountDropdown">
                    <img 
                        :src="profileData.image ? `${profileData.image}` : image_path" alt="avatar"
                        style="width: 40px;height: 40px" 
                        class="i-user-avatar rounded cursor-pointer "
                    >
                <div class="absolute ws-profile-menu flex flex-col py-4 px-5 bg-primary-three shadow-one rounded-lg"
                    v-if="accountDropdown">
                    <router-link :to="{ name: 'workspace_dashboard' }">
                        <a class="text-primary-one opacity-60 font-poppins fs-14 cursor-pointer i-profile-menu">Workspace</a>
                    </router-link>
                    <router-link :to="{ name: 'profile' }" class="mt-4">
                        <a class="text-primary-one opacity-60 font-poppins fs-14 cursor-pointer i-profile-menu">My
                            Profile</a>
                    </router-link>
                    <a class="text-primary-one opacity-60 mt-4 font-poppins fs-14 cursor-pointer i-profile-menu"
                    @click="hideAccountDropdown()">Public Profile</a>
                    <a class="text-primary-one opacity-60 mt-4 font-poppins fs-14 cursor-pointer i-profile-menu"
                    @click="hideAccountDropdown()">Settings</a>
                    <a class="text-primary-one opacity-60 mt-4 font-poppins fs-14 cursor-pointer i-profile-menu"
                    @click="logout()">Logout</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapMutations, mapActions, mapGetters} from "vuex";
import ProfileImage from "../../assets/images/icons/profile/profile.png";

export default {
    name: "WorkspaceMenu",
    props: ['hideProject', 'hideMenu', 'hideFeature'],
    data() {
        return {
            accountDropdown: false,
            menus: [
                {placeholder: 'workspace_dashboard', title: 'Workspace'},
                {placeholder: 'workspace_settings', title: 'Workspace'},
                {placeholder: 'profile', title: 'Profile'},
                {placeholder: 'identification.build', title: 'Build'},
                {placeholder: 'programmatic.scenario', title: 'Programmatic'},
                {placeholder: 'programmatic.scenario.view', title: 'Programmatic'},
                {placeholder: 'intel.assess', title: 'Assessment'},
                {placeholder: 'intel.assess.survey', title: 'Assessment'},
                {placeholder: 'brainstorm', title: 'Brainstorm'},
                {placeholder: 'brainstorm.show', title: 'Brainstorm'},
                {placeholder: 'definition', title: 'Definition'},
                {placeholder: 'definition.show', title: 'Definition'},
                {placeholder: 'roadmap', title: 'Roadmap'},
                {placeholder: 'roadmap.show', title: 'Roadmap'},
                {placeholder: 'communicators', title: 'Communicator'},
                {placeholder: 'communicator', title: 'Communicator'},
            ],
            image_path: ProfileImage,
        }
    },
    computed: {
        ...mapGetters({
            projectSelection: 'workspace/projectSelection',
            project: "project/project",
            profileData: "profile/data",
        }),
        getProfileMenuVisibility() {
            return this.$route.name === 'workspace_dashboard' || this.$route.name === 'workspace_settings';
        },
        getWorkspaceMenuVisibility() {
            return this.$route.name === 'profile'
        },
        getProjectMenus() {
            return this.$route.name !== 'profile' && this.$route.name !== 'workspace_dashboard' && this.$route.name !== 'workspace_settings' && this.$route.name !== 'notification';
        },
        getMenuTitle() {
            let instMenuObj = this.menus.find(item => item.placeholder === this.$route.name);
            if (instMenuObj) {
                return instMenuObj.title;
            }
            return 'Workspace';
        },
        isRoadmap() {
            return this.$route.name == 'roadmap.show'
        },
    },
    created() {
        this.fetchProfileData();
    },
    methods: {
        ...mapMutations({
            toggleRightBar: 'toggleRightBar'
        }),
        ...mapActions({
            fetchProfileData: "profile/fetchProfileData",
        }),
        showAccountDropdown() {
            this.accountDropdown = !this.accountDropdown;
        },
        hideAccountDropdown() {
            this.accountDropdown = false;
        },
        logout() {
            this.accountDropdown = false;
            localStorage.removeItem('_user');
            localStorage.removeItem('_token_');
            this.$router.push({name: 'login'});
        }
    }

}
</script>

<style scoped>
.menu-project-title {
    width: 280px;
}

.roadmap-icon {
    width: 20px;
    height: 20px;
}
</style>
